import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';

import { DatePipe } from '@angular/common';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { NotificationService } from 'carbon-components-angular';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './header/header.component';
import { CarbonModule } from './shared/carbon.module';
import { AuthService } from './shared/service/auth/auth.service';
import { DbService } from './shared/service/db.service';
import { LoggerService } from './shared/service/logger/logger.service';
import { ActivationModalComponent } from './starter-home/components/activation-modal/activation-modal.component';
import { RootUserFormModalComponent } from './starter-home/components/root-user-form-modal/root-user-form-modal.component';

@NgModule({
  declarations: [AppComponent, HeaderComponent, ActivationModalComponent, RootUserFormModalComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CarbonModule,
    LoggerModule.forRoot({
      level: environment.production ? NgxLoggerLevel.LOG : NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.LOG,
      timestampFormat: 'EEEE, MMM d y, h:mm a z', // Monday, June 15 2015, 12:22 PM GMT-6
      disableFileDetails: true
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig, 'cloud'),
    AngularFireStorageModule,
    AngularFireAuthModule
    // AngularFireModule.initializeApp(environment.firebaseConfig)
  ],
  exports: [CarbonModule],
  providers: [DbService, NotificationService, DatePipe, AuthService, LoggerService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}
