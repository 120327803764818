import {Pouch} from "./pouch";
import {Constants} from "./constants";

export class MerchantKey implements Pouch {
	_id!: string;
	collection = Constants.APP_KEY;
	state: KeyState = KeyState.NONE;
	expirationDate!: Date;
	creationDate: Date = new Date();
}

export enum KeyState {
	NONE,
	ACTIVATED,
	EXPIRED
}
