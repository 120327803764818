import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(value: number): string {
    if (value === null || isNaN(value)) {
      return '';
    }

    // Convertir el número a una cadena
    let formattedValue = value.toString();

    // Separar la parte entera de la parte decimal
    const parts = formattedValue.split('.');

    // Agregar comas cada tres dígitos en la parte entera
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Asegurar que la parte decimal tenga dos dígitos
    if (parts[1] && parts[1].length < 2) {
      parts[1] = parts[1].padEnd(2, '0');
    } else if (!parts[1]) {
      // Si no hay parte decimal, agregar ".00"
      parts[1] = '00';
    }

    // Unir la parte entera y la parte decimal y devolver el resultado
    return parts.join('.');
  }

}
