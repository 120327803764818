<ibm-modal
	class="acv-modal"
	[size]="'md'"
	[open]="open"
	(overlaySelected)="closeModal()"
	[cdsTheme]="'g10'">

	<ibm-modal-header
		(closeSelect)="closeModal()">
		<p>Ingresa tú clave de comerciante para poder activar tú producto.</p>
	</ibm-modal-header>

	<section class="cds--modal-content">
		<form [formGroup]="keyForm">

			<div class="bx--form-item bx--text-input-wrapper">

				<cds-label
					labelInputID="appKey"
					textInput
					[helperText]="helperText"
					[invalid]="invalidKey"
					[invalidText]="invalidText"
					[warn]="showWarn"
					[warnText]="warnText"
					[disabled]="disabledLabel"
					[skeleton]="skeletonLabel"

				>
					<input
						cdsText
						[size]="'sm'"
						[invalid]="invalidKey"
						id="appKey"
						[style]="'dark'"
						formControlName="appKey"
						[autocomplete]="autocomplete"
						[placeholder]="placeholder"
						[cdsTheme]="'g10'"
						[value]="inputValue"
						[skeleton]="skeletonInput"
						required
						maxlength="36"
						minlength="36"
					>
				</cds-label>

				<ibm-inline-loading
					#loading
					[state]="loadingState"
					(onSuccess)="onSuccess()"
					[loadingText]="loadingText"
					[successText]="successText"
					[errorText]="errorText"
				></ibm-inline-loading>

			</div>
		</form>
	</section>
	<ibm-modal-footer class="cds--modal-footer--three-button">
		<button class="cds--btn cds--btn--secondary" (click)="closeModal()">
			Cancelar
		</button>
		<button class="cds--btn cds--btn--primary "
				modal-primary-focus
				[disabled]="!this.canSubmit"
				(click)="requestKeyValidation()">
			Activar
		</button>
	</ibm-modal-footer>
</ibm-modal>
