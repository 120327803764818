import {
	AfterContentChecked,
	ChangeDetectorRef,
	Component,
	Host,
	Inject,
	OnChanges,
	OnInit,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { DbService } from './shared/service/db.service';
import { HeaderComponent } from './header/header.component';
import { AuthService } from './shared/service/auth/auth.service';
import { LoggerService } from './shared/service/logger/logger.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	@ViewChild(HeaderComponent) headerComponent: HeaderComponent;
	isLoggedIn = false;

	constructor(
		private dbService: DbService,
		private authService: AuthService,
		private loggerService: LoggerService) {
		// console.log(this.headerComponent.hasHamburger);
	}

	ngOnInit(): void {
		this.authService.userDetailsObs.subscribe((userDetails) => {
			this.isLoggedIn = this.authService.checkUserIsAuth();
			this.loggerService.debugLog('userDetails sub', userDetails);
		});
	}

	closeNavBar() {
		if (this.headerComponent != null) {
			this.headerComponent.hamburgerActive = false;
		}
	}

}
