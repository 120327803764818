import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  constructor(private logger: NGXLogger) {}

  infoLog(message: string) {
    this.logger.info(message);
  }

  errorLog(message: string, ...error: any) {
    this.logger.error(message, ...error);
  }

  warnLog(message: string, ...params: any) {
    this.logger.warn(message, ...params);
  }

  debugLog(message: string, ...params: any) {
    this.logger.debug(message, ...params);
  }

  traceLog(message: string, ...params: any) {
    this.logger.trace(message, ...params);
  }
}
