<ibm-modal
	class="user-modal"
	[size]="'sm'"
	[open]="open"
	(overlaySelected)="closeModal()"
	[cdsTheme]="'g10'">

	<ibm-modal-header>
		<p>Registro tu <strong>usuario</strong> administrador</p>
	</ibm-modal-header>

	<section class="cds--modal-content">

		<div class="bx--form-item bx--text-input-wrapper">

			<div [formGroup]="rootUserForm">

				<cds-label
					textInput
					helperText="">
					Nombres
					<input
						ibmText
						name="name"
						size="md"
						placeholder="Jhon Albert"
						formControlName="name"
						[autocomplete]="true"
					/>
				</cds-label>

				<cds-label
					textInput
					helperText="">
					Apellidos
					<input ibmText
						   name="lastName"
						   placeholder="Doe Zeng"
						   formControlName="lastName"
						   [autocomplete]="true"
					/>
				</cds-label>

				<cds-label
					textInput
					helperText="">
					Correo
					<input ibmText
						   name="email"
						   placeholder="user@bhit.com.mx"
						   formControlName="email"
						   [autocomplete]="true"
					/>
				</cds-label>

				<cds-label helperText="">
					Contraseña
					<input
						ibmText
						[type]="'password'"
						name="password"
						placeholder="******************************"
						formControlName="password"
						[autocomplete]="false"
					/>
				</cds-label>

				<cds-label helperText="">
					Confirma tu contraseña
					<input
						ibmText
						[type]="'password'"
						name="passwordConfirm"
						placeholder="******************************"
						formControlName="passwordConfirm"
						[autocomplete]="false"
					/>
				</cds-label>

			</div>

			<ibm-inline-loading
				#loading
				[state]="loadingState"
				(onSuccess)="onSuccess()"
				[loadingText]="loadingText"
				[successText]="successText"
				[errorText]="errorText"
				class="inline-loading-27-style">
			</ibm-inline-loading>

		</div>
	</section>

	<ibm-modal-footer class="cds--modal-footer--three-button">
		<button class="cds--btn cds--btn--secondary" (click)="closeModal()">
			Cancelar
		</button>
		<button class="cds--btn cds--btn--primary user-modal"
				[disabled]="!this.rootUserForm.valid"
				modal-primary-focus
				(click)="createUser()">
			Crear
			<svg class="cds--btn__icon"
				 cdsIcon="user--avatar"
				 size="20">
			</svg>
		</button>
	</ibm-modal-footer>
</ibm-modal>
