import { EventEmitter, Injectable, Output } from '@angular/core';
import { Constants } from '../model/constants';
import { KeyState, MerchantKey } from '../model/merchant-key';

import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
import PouchDBUpsert from 'pouchdb-upsert';
import cryptoPouch from 'crypto-pouch';

import { DbUtils } from './DbUtils';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
	providedIn: 'root',
})
export class DbService {
	protected isInstantiated = false;
	@Output()
	private listener: EventEmitter<any> = new EventEmitter();
	private db: any;

	constructor() {
		// inicializar los plugins de pouchdb
		PouchDB.plugin(PouchDBUpsert);
		PouchDB.plugin(PouchDBFind);
		PouchDB.plugin(cryptoPouch);

		if (!this.isInstantiated) {
			this.db = new PouchDB(Constants.APP_DB_NAME, {
				auto_compaction: true,
				adapter: 'idb',
				prefix: 'bhit_',
			});
			this.isInstantiated = true;
		}

		this.db.info().then((info: any) => {
			console.log(info);
			this.db.createIndex({
				index: {fields: ['collection']},
			});
		});
	}

	getKey() {
		return this.db.get(Constants.APP_KEY);
	}

	createKey() {
		const key: MerchantKey = new MerchantKey();
		key.expirationDate = new Date();
		key.state = KeyState.NONE;
		return this.db.put(key);
	}

	getTicketConfig() {
		return this.db.get(Constants.TICKET);
	}



	saveTicketCfg(ticketCfg: any) {
		console.log(ticketCfg);
		return this.db.post(ticketCfg);
	}

	find(key: string) {
		return this.db.find({
			selector: {
				collection: {$eq: key},
			},
		});
	}

	fullText(text: string, fields: string[]) {
		return this.db.search({
			query: text,
			fields: fields,
			include_docs: true,
			highlighting: true,
		});
	}

	getLastItemId(collection: string): Promise<string | number> {
		return this.db
			.find({
				selector: {
					collection: {$eq: collection},
				},
				sort: [{_id: 'desc'}],
				// sort: [{ index: 'desc' }],
			})
			.then((result: any) => {
				if (result.docs.length > 0) {
					return result.docs[0]._id;
				} else {
					return 0;
				}
			});
	}

	// getLastItemIdCajas(collection: string): Promise<string | number> {
	// 	return this.db
	// 		.createIndex({
	// 			index: {
	// 				fields: ['index'],
	// 			},
	// 		})
	// 		.then(() => {
	// 			this.db
	// 				.find({
	// 					selector: {
	// 						collection: { $eq: collection },
	// 					},
	// 					sort: [index],
	// 					// sort: [{ index: 'desc' }],
	// 				})
	// 				.then((result: any) => {
	// 					if (result.docs.length > 0) {
	// 						console.log(result);
	// 						return result.docs[0]._id;
	// 					} else {
	// 						return 0;
	// 					}
	// 				});
	// 		});
	// }

	saveItem(item: any, collection: string): Promise<any> {
		return this.getLastItemId(collection)
			.then((lastItemId: string | number) => {
				item._id = uuidv4();
				item.collection = collection;
				return this.db.put(item);
			})
			.catch((error: any) => {
				console.error(
					`Error al guardar el elemento en la colección ${collection}:`,
					error
				);
				throw error;
			});
	}

	updateItem(item: any, collection: string): Promise<any> {
		return this.db
			.upsert(item._id, (doc: any) => {
				doc = item;
				return doc;
			})
			.catch((error: any) => {
				console.error(
					`Error al actualizar el elemento en la colección ${collection}:`,
					error
				);
				throw error;
			});
	}

	getItemById(itemId: string, collection: string): Promise<any> {
		return this.db
			.get(itemId)
			.then((item: any) => {
				if (item.collection === collection) {
					return item;
				} else {
					throw new Error(
						`El elemento con ID ${itemId} no pertenece a la colección ${collection}`
					);
				}
			})
			.catch((error: any) => {
				console.error(
					`Error al obtener el elemento con ID ${itemId} de la colección ${collection}:`,
					error
				);
				throw error;
			});
	}

	getItemsByField(
		field: string,
		value: any,
		collection: string
	): Promise<any> {
		return this.db
			.find({
				selector: {
					collection: {$eq: collection},
					[field]: {$eq: value},
				},
			})
			.then((result: any) => {
				if (result.docs.length > 0) {
					return result.docs; // Devuelve todos los documentos encontrados
				} else {
					throw new Error(
						`No se encontró ningún elemento en la colección ${collection} con ${field} = ${value}`
					);
				}
			})
			.catch((error: any) => {
				console.error(
					`Error al obtener el elemento de la colección ${collection} con ${field} = ${value}:`,
					error
				);
				throw error;
			});
	}

	deleteItem(itemId: string, collection: string): Promise<any> {
		return this.db
			.get(itemId)
			.then((item: any) => {
				if (item.collection === collection) {
					return this.db.remove(item);
				} else {
					throw new Error(
						`El elemento con ID ${itemId} no pertenece a la colección ${collection}`
					);
				}
			})
			.catch((error: any) => {
				console.error(
					`Error al eliminar el elemento con ID ${itemId} de la colección ${collection}:`,
					error
				);
				throw error;
			});
	}

	deleteItemById(itemId: string, collection: string): Promise<any> {
		return this.db
			.find({
				selector: {
					_id: {$eq: itemId},
				},
			})
			.then((result: any) => {
				if (result.docs.length > 0) {
					return this.db.remove(result.docs[0]);
				} else {
					throw new Error(
						`No se encontró ningún elemento en la colección ${collection} con ID ${itemId}`
					);
				}
			})
			.catch((error: any) => {
				console.error(
					`Error al eliminar el elemento con ID ${itemId} de la colección ${collection}:`,
					error
				);
				throw error;
			});
	}
}
