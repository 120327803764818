import {Injectable} from '@angular/core';
import {DbService} from "../../shared/service/db.service";
import {MerchantKey} from "../../shared/model/merchant-key";

@Injectable({
	providedIn: 'root'
})
export class MerchantKeyService {

	constructor(private dbService: DbService) {
	}


	async firstKey(): Promise<MerchantKey> {
		return this.dbService.createKey();
	}

	async checkKeyStatus(): Promise<MerchantKey> {
		return this.dbService.getKey();
	}

	updateKeyStatus(key: MerchantKey): any {
	}
}
