<ibm-modal class="user-modal" [size]="'sm'" [open]="open" (overlaySelected)="cancel()" [cdsTheme]="'g10'">

    <ibm-modal-header [showCloseButton]="false">
        <h2>{{ title }}</h2>
    </ibm-modal-header>

    <section class="cds--modal-content">
        <p>{{ message }}</p>
    </section>

    <ibm-modal-footer class="cds--modal-footer--three-button">
        <button class="cds--btn cds--btn--secondary" (click)="cancel()">
            Cancelar
        </button>
        <button class="cds--btn cds--btn--primary user-modal" modal-primary-focus (click)="confirm()">
            Confirmar
        </button>
    </ibm-modal-footer>
</ibm-modal>