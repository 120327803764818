import {Component, EventEmitter, Injector, Output} from '@angular/core';
import {BaseModal, ModalService} from "carbon-components-angular";
import {FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {MerchantKeyService} from "../../service/merchant-key.service";
import {Observer} from "rxjs";
import {MerchantKey} from "../../../shared/model/merchant-key";


const sampleUUID = '123e4567-e89b-12d3-a456-426614174000';


@Component({
	selector: 'activation-modal',
	templateUrl: './activation-modal.component.html',
	styleUrls: ['./activation-modal.component.scss']
})
export class ActivationModalComponent extends BaseModal {

	@Output()
	appKeyActivated = new EventEmitter<{
		status: string;
		activated: boolean
	}>();

	protected receivedData: Observer<boolean>;


	label = '';
	placeholder = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx';
	keyRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/i;

	helperText = 'Encontrarás tu llave en tu correo de bienvenida.';
	invalidKey = false;
	invalidText = 'Llave invalida';

	showWarn = false;
	warnText = 'Clave con formato inválido';

	disabledLabel = false;
	skeletonLabel = true;
	autocomplete = false;

	keyForm!: FormGroup;
	keyFormControl!: FormControl;
	validForm = false;

	inputValue = ''
	skeletonInput = true;
	ssid: string = '';

	canSubmit: boolean = false;

	loadingState = 'hidden';
	loadingText = 'activando ... ';
	successText = 'llave activada de forma correcta';
	errorText = 'error al activar la llave';

	constructor(
		protected merchantKeyService: MerchantKeyService,
		protected injector: Injector,
		protected fb: FormBuilder,
		protected modalService: ModalService) {
		super();
		// obtenemos el ssid, este será usado para poder consumir el servicio de activación de llave.
		this.ssid = this.injector.get('ssid');
		this.receivedData = this.injector.get('data');
		console.log(this.ssid)
		console.log(this.receivedData)
		this.createForm();
	}

	ngOnInit(): void {
		this.validateKey();
		this.loadingState = 'inactive';
		this.loadingText = '';
		setTimeout(() => {
				this.skeletonInput = false;
				this.skeletonLabel = false;
			},
			900);
	}


	createForm() {
		this.keyFormControl =
			new FormControl('',
				[
					Validators.pattern(this.keyRegex),
					Validators.required,
					Validators.minLength(36),
					Validators.maxLength(36)
				]);

		this.keyForm = this.fb
			.group({
				appKey: this.keyFormControl
			});

		console.log('keyForm', this.keyForm);
		console.log('controls', this.keyForm.controls);

		console.log('key control', this.keyForm.controls['appKey']);

		this.keyForm.controls['appKey']
			.valueChanges
			.subscribe(value => {
				this.validateForm();
			})
	}

	overlay(event: any) {
		super.closeModal();
	}

	validateKey() {
		this.merchantKeyService.checkKeyStatus()
			.then((key: MerchantKey) => {
				console.log('[Llave  encontrada,valor:]', key);
			}).catch(reason => {
				console.error('[Llave no encontrada, generando valor inicial]', reason);
				this.initKey();
			}
		);
	}

	initKey() {
		this.merchantKeyService.firstKey()
			.then(newKey => {
				console.info('[KEY : NONE CREATED]', newKey)
			}).catch(reason => {
			console.error(reason);
		});
	}

	validateForm() {
		if (this.keyForm.controls['appKey'].errors) {
			let errors: ValidationErrors = this.keyForm.controls['appKey'].errors;
			console.log('exisen errores', errors);
			this.showWarn = true;
		} else {
			this.showWarn = false;
			this.helperText = '';
			this.canSubmit = true;
		}
	}

	requestKeyValidation() {
		console.log('requestKeyValidation')
		this.loadingText = 'activando...';
		this.loadingState = 'active';
		this.skeletonInput = true;
		this.skeletonLabel = true;
		this.canSubmit = false;
		setTimeout(() => {
				this.skeletonInput = false;
				this.skeletonLabel = false;
				this.onSuccess();
			},
			2000);
	}

	onSuccess() {
		console.log('[Llave activada]')
		this.loadingState = 'finished';
		this.receivedData.next(true);
		setTimeout(() => {
				this.closeModal();
			},
			800);
	}

}
