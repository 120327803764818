import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		redirectTo: '/home',
		pathMatch: 'full'
	}, // redirect to `Home Component`
	{
		path: 'home',
		loadChildren: () => import('./log-in/log-in.module').then((m) => m.LogInModule)
	},
	// {
	//   path: 'dashboard',
	//   loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
	// },
	{
		path: 'real-state',
		loadChildren: () => import('./real-state/real-state.module').then((m) => m.RealStateModule)
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			useHash: true,
			bindToComponentInputs: true,
			onSameUrlNavigation: 'ignore',
			urlUpdateStrategy: 'eager'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
