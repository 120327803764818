// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  pageTitle: 'BCC Inmobiliaria',
  production: false,
  firebaseConfig: {
  	apiKey: 'AIzaSyDNvhOgZ4KYXIrb9vaYvnIrfu2Hh1n0lp8',
  	authDomain: 'bcc-inmobiliaria-app.firebaseapp.com',
  	databaseURL: 'https://bcc-inmobiliaria-app-default-rtdb.firebaseio.com',
  	projectId: 'bcc-inmobiliaria-app',
  	storageBucket: 'bcc-inmobiliaria-app.appspot.com',
  	messagingSenderId: '197522708658',
  	appId: '1:197522708658:web:9f3a556a40e66470c7d1ab',
  	measurementId: 'G-7MFTZCJH6X'
  }
  // firebaseConfig: {
  //   apiKey: 'AIzaSyAfk7AiJ68JSWb_Hf8TdggO_wZmVteQJf4',
  //   authDomain: 'bcc-inmobiliaria-web.firebaseapp.com',
  //   projectId: 'bcc-inmobiliaria-web',
  //   storageBucket: 'bcc-inmobiliaria-web.firebasestorage.app',
  //   messagingSenderId: '863182688531',
  //   appId: '1:863182688531:web:3de0326c2ac61605700ae6',
  //   measurementId: 'G-6L6BS40948'
  // }
};
