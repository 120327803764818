<div cdsTheme="g100">
  <ibm-header
    class="bx--cloud-header"
    [brand]="brand"
    [name]="appTitle"
    [route]="['/']"
  >
    <cds-hamburger
      *ngIf="showGlobal"
      [active]="hamburgerActive"
      (selected)="openMenu()"
    >
    </cds-hamburger>

    <ibm-header-navigation *ngIf="showGlobal">
      <cds-header-item
        (click)="selectedHeaderNavMenu()"
        [routerLink]="['dashboard']"
      >
        Dashboard
      </cds-header-item>
      <cds-header-item
        (click)="selectedHeaderNavMenu()"
        [routerLink]="['home/ventas']"
      >
        Ventas
      </cds-header-item>
      <cds-header-item
        (click)="selectedHeaderNavMenu()"
        [routerLink]="['home/reportes']"
      >
        Reportes
      </cds-header-item>
      <cds-header-item
        (click)="selectedHeaderNavMenu()"
        [routerLink]="['home/soporte']"
      >
        Soporte
      </cds-header-item>
    </ibm-header-navigation>

    <ibm-header-global *ngIf="showGlobal">
      <cds-header-action title="action">
        <svg
          cdsIcon="notification"
          size="20"
        ></svg>
      </cds-header-action>
      <cds-header-action title="action">
        <ibm-overflow-menu
          [customTrigger]="customTrigger"
          [offset]="offset"
          [flip]="true">
          <ibm-overflow-menu-option (selected)="logOut()">Cerrar Sesión</ibm-overflow-menu-option>
        </ibm-overflow-menu>
        <cds-placeholder></cds-placeholder>
        <ng-template #customTrigger
          ><svg
            cdsIcon="user--avatar"
            size="20"
          ></svg
        ></ng-template>
      </cds-header-action>
    </ibm-header-global>
  </ibm-header>

  <ibm-sidenav
    *ngIf="showGlobal"
    [ariaLabel]="'menú principal'"
    [expanded]="hamburgerActive"
    [rail]="true"
    [hidden]="false"
    [allowExpansion]="false"
    style="margin-top: 3rem"
  >
    <cds-sidenav-item
      (click)="selectedSideNavMenu()"
      [route]="['real-state']"
      [title]="'propiedades'"
    >
      <svg
        cdsIcon="building"
        size="24"
      ></svg>
      Propiedades
    </cds-sidenav-item>



  </ibm-sidenav>
</div>
