export class Constants {

	public static APP_DB_NAME = 'offline_pos_db';
	public static APP_KEY = 'merchant_key';
	public static APP_STEPS = 'activation_steps';
	public static APP_INFO = 'app_info';
	public static TICKET_CFG = 'ticket_config';
	public static TICKET = 'ticket';
	public static PRODUCTS = 'products';
}
