import { Component, Injector } from '@angular/core';
import { BaseModal, ModalService } from 'carbon-components-angular';

@Component({
  selector: 'confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent extends BaseModal {

  title: string = '';
  message: string = '';

  constructor(
    private modalService: ModalService,
    private injector: Injector
  ) {
    super();
    this.title = this.injector.get('title');
    this.message = this.injector.get('message');
   }

  confirm(): void {
    localStorage.setItem('confirmation', 'true');
    this.modalService.destroy();
  }

  cancel(): void {
    localStorage.setItem('confirmation', 'false');
    this.modalService.destroy();
  }

}
