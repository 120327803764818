import { Injectable } from '@angular/core';
import { DbService } from './db.service';

@Injectable({
	providedIn: 'root'
})
export class SettingsService {

	constructor(
		private db: DbService
	) {
	}

	getSettings(): Promise<any> {
		return this.db.find('settings');
	}

	saveSettings(settings: any): Promise<any> {
		return this.db.saveItem(settings, 'settings');
	}

	getSettingsById(id: string): Promise<any> {
		return this.db.getItemById(id, 'settings');
	}

	updateSettings(settings: any): Promise<any> {
		return this.db.updateItem(settings, 'settings');
	}

}
