import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Add16 from '@carbon/icons/es/add/16';
import Bottles24 from '@carbon/icons/es/bottles--01/24';
import Building from '@carbon/icons/es/building/24';
import Categories from '@carbon/icons/es/categories/24';
import Document24 from '@carbon/icons/es/document/24';
import Filter16 from '@carbon/icons/es/filter/16';
import Group from '@carbon/icons/es/group/24';
import Settings from '@carbon/icons/es/settings/24';
import Store from '@carbon/icons/es/store/24';
import { IconService } from 'carbon-components-angular';
import { AuthService } from '../shared/service/auth/auth.service';
import { SettingsService } from '../shared/service/settings.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	settings: any;

	// adds padding to the top of the document, so the content is below the header
	@HostBinding('class.cds--header') headerClass = true;
	showGlobal = false;
	brand = '--';
	appTitle = '----';
	public hasHamburger = false;
	hamburgerActive = false;
	hasActiveChild = true;
	offset: { x: 0; y: 0 };

	constructor(
		private router: Router,
		protected iconService: IconService,
		private settingsService: SettingsService,
		private authService: AuthService) {
		router.events.subscribe((r: any) => this.handleActiveUrl(r.url));
		this.iconService.registerAll([
			Add16,
			Filter16,
			Bottles24,
			Document24,
			Categories,
			Store,
			Group,
			Settings,
			Building
		]);
		console.log("HeaderComponent");
	}

	async ngOnInit() {
		await this.loadSettings();
	}

	async loadSettings() {
		await this.settingsService.getSettings().then((res) => {
			this.settings = res;
		});
		if (this.settings.length > 0) {
			if (this.settings.docs[0].nombre !== '') {
				this.appTitle = this.settings.docs[0].nombre;
			} else {
				this.appTitle = 'Application';
			}
			if (this.settings.docs[0].brand !== '') {
				this.brand = this.settings.docs[0].brand;
			} else {
				this.brand = 'BHIT';
			}
		}
		this.brand = 'BCC';
		this.appTitle = 'Inmobiliaria';
	}

	handleActiveUrl(url: string) {
		const path: String | '' = url;
		if (undefined !== path) {
			this.showGlobal = path.includes('/pos') || path.includes('/inventory');
		}
		this.showGlobal = true;
	}

	openMenu() {
		console.log('[open menu]', this.hamburgerActive);
		return (this.hamburgerActive = !this.hamburgerActive);
	}

	selectedSideNavMenu() {
		console.log('[side nav selected]', this.hamburgerActive);
		if (this.hamburgerActive) {
			this.hamburgerActive = false;
		} else {
			this.hamburgerActive = true;
			setTimeout(() => {
				this.hamburgerActive = false;
			}, 950);
		}
	}

	selectedHeaderNavMenu() {
		if (this.hamburgerActive) {
			this.hamburgerActive = false;
		}
	}

	onAction(action: string) {
		console.log(`Action selected: ${action}`);
		// Perform action based on the selected option
	}

	logOut() {
		this.authService.SignOut();
	}
}
