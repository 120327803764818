import { NgModule } from '@angular/core';

// carbon-components-angular default imports
import {
	AccordionModule,
	ButtonModule,
	CheckboxModule,
	ContainedListModule, ContextMenuModule, DatePickerModule,
	DialogModule,
	FileUploaderModule,
	GridModule,
	IconModule,
	IconService,
	InlineLoadingModule,
	InputModule,
	ListModule,
	ModalModule,
	ModalService,
	NotificationModule, NumberModule,
	OverflowMenuDirective,
	PaginationModule,
	PanelModule,
	PlaceholderModule,
	ProgressIndicatorModule,
	RadioModule, SelectModule,
	SkeletonModule, SliderModule,
	StructuredListModule,
	TableModule,
	TabsModule,
	TagModule,
	ThemeModule,
	TilesModule,
	ToggleModule,
	UIShellModule
} from 'carbon-components-angular';

// Icons
import Notification20 from '@carbon/icons/es/notification/20';
import UserAvatar20 from '@carbon/icons/es/user--avatar/20';
import Cube20 from '@carbon/icons/es/cube/20';
import CSV20 from '@carbon/icons/es/CSV/20';

// Components
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PricePipe } from './pipes/price.pipe';
import { ConfirmationModalComponent } from './modal/confirmation-modal/confirmation-modal.component';
import { NgxPrintModule } from 'ngx-print';


@NgModule({

	imports: [
		CommonModule,
		GridModule,
		ListModule,
		TabsModule,
		RadioModule,
		ButtonModule,
		ModalModule,
		InlineLoadingModule,
		StructuredListModule,
		InputModule,
		ReactiveFormsModule,
		NotificationModule,
		IconModule,
		TagModule,
		AccordionModule,
		ContainedListModule,
		UIShellModule,
		IconModule,
		ThemeModule,
		ModalModule,
		InputModule,
		InlineLoadingModule,
		TilesModule,
		ProgressIndicatorModule,
		PanelModule,
		GridModule,
		PlaceholderModule,
		TableModule,
		CheckboxModule,
		DialogModule,
		ToggleModule,
		FileUploaderModule,
		PaginationModule,
		NgxPrintModule,
		NumberModule,
		SliderModule,
		SelectModule,
		DatePickerModule,
    ContextMenuModule
	],
	exports: [
		DatePickerModule,
		SelectModule,
		CommonModule,
		GridModule,
		ListModule,
		TabsModule,
		RadioModule,
		ButtonModule,
		ModalModule,
		InlineLoadingModule,
		StructuredListModule,
		InputModule,
		ReactiveFormsModule,
		PlaceholderModule,
		NotificationModule,
		IconModule,
		TagModule,
		AccordionModule,
		ContainedListModule,
    ContextMenuModule,

		UIShellModule,
		IconModule,
		ThemeModule,
		ModalModule,
		InputModule,
		InlineLoadingModule,
		ProgressIndicatorModule,
		PanelModule,
		GridModule,
		TilesModule,
		SkeletonModule,
		TableModule,
		CheckboxModule,
		DialogModule,
		ToggleModule,
		FileUploaderModule,
		PaginationModule,
		NumberModule,
		SliderModule

	],
	providers: [ModalService, OverflowMenuDirective],
	declarations: [
		PricePipe,
		ConfirmationModalComponent
	]
})
export class CarbonModule {

	constructor(protected iconService: IconService) {
		iconService.registerAll([
			Notification20,
			UserAvatar20,
			Cube20,
			CSV20
		]);
	}
}
