import {Component, EventEmitter, Injector, Input, Output} from '@angular/core';
import {BaseModal, ModalService} from "carbon-components-angular";
import {Observer} from "rxjs";
import {FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";

@Component({
	selector: 'root-user-form-modal',
	templateUrl: './root-user-form-modal.component.html',
	styleUrls: ['./root-user-form-modal.component.scss']
})
export class RootUserFormModalComponent extends BaseModal {

	@Input() tile5Theme = "dark";

	@Input() inlineLoading27Status = "active";
	@Input() inlineLoading27LoadingText = "Loading...";
	@Input() inlineLoading27SuccessText = "Finished.";
	@Input() inlineLoading27ErrorText = "Error!";
	@Input() inlineLoading27SuccessDelay = 1500;

	@Output() inlineLoading27OnSuccess = new EventEmitter();

	protected receivedData!: Observer<boolean>;
	ssid: string = '';

	helperText = '';
	showWarn = false;
	warnText = 'Clave con formato inválido';
	rootUserForm!: FormGroup;
	StrongPasswordRegx: RegExp =
		/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;
	validForm = false;
	skeletonInput = true;
	canSubmit: boolean = false;

	loadingState = 'hidden';
	loadingText = 'Creando usuario';
	successText = 'Usuario creado con éxito';
	errorText = 'Error al crear el usuario';

	constructor(
		protected injector: Injector,
		protected fb: FormBuilder,
		protected modalService: ModalService) {
		super();
		// obtenemos el ssid, este será usado para poder consumir el servicio de activación de llave.
		this.ssid = this.injector.get('ssid');
		this.receivedData = this.injector.get('data');
		console.log(this.ssid)
		console.log(this.receivedData)

		this.loadingState = 'inactive';
		this.loadingText = '';
		this.createForm();
	}

	createForm() {

		let nameControl = new FormControl('',
			[
				Validators.required
			]);

		let lastNameControl = new FormControl('',
			[
				Validators.required
			]);

		let rootUserControl =
			new FormControl('',
				[
					Validators.email
				]);

		let pwdUserControl = new FormControl('',
			[
				Validators.pattern(this.StrongPasswordRegx)
			]);

		let pwdConfirmControl = new FormControl('',
			[
				Validators.pattern(this.StrongPasswordRegx)
			]);

		this.rootUserForm = this.fb
			.group({
				name: nameControl,
				lastName: lastNameControl,
				email: rootUserControl,
				password: pwdUserControl,
				passwordConfirm: pwdConfirmControl
			});

		console.log('rootUserControl', this.rootUserForm);
		console.log('controls', this.rootUserForm.controls);

		console.log('key control', this.rootUserForm.controls['email']);


		this.rootUserForm
			.valueChanges
			.subscribe(value => {
				this.validateForm();
			})
	}

	validateForm() {
		let nameError: ValidationErrors | null = this.rootUserForm.controls['name'].errors;
		let lastNameError: ValidationErrors | null = this.rootUserForm.controls['lastName'].errors;
		let emailError: ValidationErrors | null = this.rootUserForm.controls['email'].errors;
		let pwdError: ValidationErrors | null = this.rootUserForm.controls['password'].errors;

		console.log('[name error]', nameError);
		console.log('[last name error]', lastNameError);
		console.log('[email error]', emailError);
		console.log('[pwd error]', pwdError);

		if (this.rootUserForm.controls['email'].errors) {
			let errors: ValidationErrors | null = this.rootUserForm.controls['email'].errors;
			console.log('exisen errores', errors);
			this.showWarn = true;
		} else {
			this.showWarn = false;
			this.helperText = '';
			this.canSubmit = true;
		}
		console.log('User Form State:', this.rootUserForm.valid)
	}

	createUser() {
		this.loadingText = 'creando';
		this.loadingState = 'active';
		setTimeout(() => {
				// this.skeletonInput = false;
				// this.skeletonLabel = false;
				this.onSuccess();
			},
			2000);
	}

	onSuccess() {
		console.log('[Usuario creado]')
		this.loadingState = 'finished';
		this.receivedData.next(true);
		setTimeout(() => {
				this.closeModal();
			},
			800);
	}


}
